"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
const Theme_1 = require("../Theme");
function CardTitle(props) {
    return ((0, jsx_runtime_1.jsxs)(react_native_1.View, { style: [
            {
                flexDirection: 'row',
                alignItems: 'center'
            },
            props.style
        ], children: [(0, jsx_runtime_1.jsxs)(react_native_1.View, { style: { flex: 1 }, children: [(0, jsx_runtime_1.jsx)(react_native_1.Text, { "aria-label": props["aria-label"] ?? props.title, style: {
                            fontSize: 18,
                            fontWeight: '500',
                            color: Theme_1.THEME.Text
                        }, children: props.title }), !!props.subtitle &&
                        (0, jsx_runtime_1.jsx)(react_native_1.Text, { "aria-label": props.subtitle, style: {
                                fontSize: 14,
                                color: Theme_1.THEME.TextDisabled
                            }, children: props.subtitle })] }), (0, jsx_runtime_1.jsx)(react_native_1.View, { children: props.children })] }));
}
exports.default = CardTitle;
