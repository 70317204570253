"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FirebaseCrashlytics = void 0;
class FirebaseCrashlytics {
    setAttribute(name, value) {
        // This is a no-op for web browser.
    }
    setAttributes(value) {
        // This is a no-op for web browser.
    }
    log(value) {
        // This is a no-op for a web browser.
    }
    crash() {
        // This is a no-op for a web browser.
    }
    RecordError(error) { }
}
exports.FirebaseCrashlytics = FirebaseCrashlytics;
const CrashLogger = new FirebaseCrashlytics();
exports.default = CrashLogger;
