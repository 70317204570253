"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppLinking = void 0;
exports.AppLinking = {
    prefixes: [
        'elevate://',
        'https://employee.nextep.com',
        'https://staging.nextep.com'
    ],
    config: {
        screens: {
            Content: {
                path: '',
                screens: {
                    HomeTab: {
                        path: 'home',
                        screens: {
                            Home: '',
                            MyInformation: 'information',
                            MyBenefits: 'benefits',
                            MyPay: 'pay',
                            PayStubs: 'pay/stubs',
                            PayStubDetail: 'pay/stubs/detail',
                            MyTaxes: 'pay/taxes',
                            HolidaySavingsHistory: 'pay/holidaysavings/history',
                            TimeAttendance: 'time'
                        }
                    },
                    CompanyTab: {
                        path: 'company',
                        screens: {
                            Company: '',
                            EmployeeManagement: 'employeemanagement',
                            PtoApprovals: 'ptoapprovals',
                            PtoBalances: 'ptoapprovals/balances',
                            BenefitRenewal: 'benefitrenewal',
                            ManageAnnouncements: 'manageannouncements'
                        }
                    },
                    HelpTab: {
                        path: 'help',
                        screens: {
                            Help: ''
                        }
                    },
                    SettingsTab: {
                        path: 'settings',
                        screens: {
                            Settings: ''
                        }
                    }
                }
            },
            Login: 'login',
            Register: 'register',
            WebScreen: 'web',
            CompanySwitch: 'settings/select',
            MultiFactorAuth: 'settings/mfa',
            ChangePassword: 'settings/password',
            ChangeUsername: 'settings/username',
            DirectDepositAddEdit: 'pay/deposit/update',
            AnnouncementEdit: 'company/announcement/update',
            AddEmployee: 'company/employeemanagement/addemployee',
            TerminateEmployee: 'company/employeemanagement/terminateemployee',
            RehireEmployee: 'company/employeemanagement/rehireemployee',
            EmployeeDetails: 'company/employeemanagement/employeedetails',
            PtoApprovalAction: 'company/ptoapprovals/respond',
            PtoRequest: 'time/request',
            DependentsEdit: 'home/information/dependents/update',
            PersonalInfoEdit: 'home/information/personalinfo/update',
            HolidaySavingsEdit: 'home/pay/holidaysavings/update',
            HolidaySavingsStop: 'home/pay/holidaysavings/stop',
            HolidaySavingsInfo: 'home/pay/holidaysavings/info',
            CreateCase: 'help/case',
            ScheduleMeeting: 'help/schedule',
            FederalWithholdingEdit: 'home/pay/taxes/federal',
            StateWithholdingEdit: 'home/pay/taxes/state',
            BenefitPopupScreen: 'home/benefits/benefitpopup',
            NotificationSettingsEdit: 'settings/notifications',
            EmailNotificationSettingsEdit: 'settings/notifications/email'
        }
    }
};
